import React, { Component } from 'react'
import "../../../Assets/Css/newDashboard.css"
import { connect } from 'react-redux'
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { logout } from "../../Login/Actions/LoginAction"
import jwt_decode from "jwt-decode";
import "bootstrap/scss/bootstrap.scss";
import { Menu, Dropdown, Input, AutoComplete } from 'antd';
import { DownOutlined, TranslationOutlined, LogoutOutlined, DeploymentUnitOutlined } from '@ant-design/icons';


class SupplierHeader extends Component {

    constructor() {
        super();

        this.state = {
            languageData: null,
            loading: true,
            isLanguageOpen: false,
            isProfileOpen: false,
            username: "",
            tokenError: false

        }
    }


    changeLanguage = (langCode) => {

        localStorage.setItem("langCode", langCode);
        window.location.reload();

    }
    showHideLanguage = () => {
        this.setState({
            isLanguageOpen: !this.state.isLanguageOpen
        })
    }

    showHideProfileMenu = () => {
        this.setState({
            isProfileOpen: !this.state.isProfileOpen
        })
    }

    logOut = () => {
        this.props.logout();
    }

    tokens() {
        try {
            let decode = jwt_decode(localStorage.getItem("token"));

            return decode.data.name;
        } catch (error) {
            return false;
        }
    }


    logOut = () => {
        this.props.logout();
    }


    changeLanguage = (langCode) => {

        localStorage.setItem("langCode", langCode);
        window.location.reload();

    }



    render() {
        const { languageData, loading } = this.props.languageData;
        const { isAuth, loginData } = this.props.loginData;

        console.log(this.props)

        return (


            <>
                <div className='container-fluid supplierHeader'>
                    <div className='headers'>
                        <div className='left'>
                            <Link to="/">

                                <img src="/Assets/img/logo.svg" alt="Tourzone Logo" />

                            </Link>
                        </div>
                        <div className='middle'>
                            
                        </div>
                        <div className='right'>
                            <ul>
                                <li>
                                    <Dropdown overlay={
                                        <Menu>

                                            <Menu.Item>
                                                <Link to="/Profile">{languageData.languages.headerCompany}</Link>
                                            </Menu.Item>
                                            
                                        </Menu>
                                    }>

                                        {isAuth ?

                                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                <DeploymentUnitOutlined />    {loginData.info.email} <DownOutlined />
                                            </a>
                                            :
                                            ""
                                        }
                                    </Dropdown>
                                </li>
                                <li>|</li>
                                <li className='me-5'>


                                    {isAuth ?
                                        <Link to="/Login" onClick={() => this.logOut()}>
                                            <LogoutOutlined />   {languageData.languages.logout}
                                        </Link>
                                        :
                                        ""
                                    }

                                </li>
                                
                            </ul>



                        </div>
                    </div>

                </div>
            </>

        )
    }
}


const mapStateToProps = (state) => ({
    loginData: state.loginData,
    languageData: state.languageData
});

export default connect(mapStateToProps, { logout })(SupplierHeader);
